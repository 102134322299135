import { useRollbar } from '@rollbar/react'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { Emoji } from 'emoji-mart'
import dynamic from 'next/dynamic'
import { twMerge } from 'tailwind-merge'
import { Loader } from 'shared/components/loader'
import { useClickOutside } from 'shared/hooks/use-click-outside'
import EmojiOutlineIcon from 'shared/icons/emoji-outline-icon'
import { ToolbarButton } from 'modules/comments/components/comment-editor/common/toolbar-button'

const Picker = dynamic(() => import('@emoji-mart/react'))

interface EmojiPickerProps {
  onEmojiSelect: (emoji: typeof Emoji.Props) => void
  iconClassName?: string
  pickerClassName?: string
}

export const EmojiPicker = ({
  onEmojiSelect,
  pickerClassName = '',
  iconClassName = '',
}: EmojiPickerProps) => {
  const rollbar = useRollbar()

  const [isPickerVisible, setIsPickerVisible] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [emojiData, setEmojiData] = useState<any>(null)

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'top',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom'],
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 6],
          },
        },
      ],
    },
  )

  const toggleEmojiPicker = useCallback(() => {
    setIsPickerVisible(prev => !prev)
  }, [])

  useClickOutside(toggleEmojiPicker, null, [popperElement, referenceElement])

  useEffect(() => {
    const loadEmojiPicker = async () => {
      if (isPickerVisible && !emojiData) {
        try {
          const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
          setEmojiData(await response.json())
        } catch (error) {
          rollbar.error('Failed to load emoji picker', error as Error)
        }
      }
    }
    loadEmojiPicker()
  }, [isPickerVisible, onEmojiSelect])

  return (
    <>
      <ToolbarButton ref={setReferenceElement} type="button" className="hidden md:block">
        <EmojiOutlineIcon
          className={twMerge('cursor-pointer text-lightgray hover:text-darkblue', iconClassName)}
          onClick={toggleEmojiPicker}
        />
      </ToolbarButton>

      {isPickerVisible && (
        <div
          ref={setPopperElement}
          style={{ ...popperStyles.popper, zIndex: 10 }}
          className={pickerClassName}
          {...popperAttributes.popper}
        >
          {emojiData ? (
            <Suspense fallback={<EmojiPickerLoader />}>
              <Picker
                data={emojiData}
                onEmojiSelect={(emoji: typeof Emoji.Props) => {
                  onEmojiSelect(emoji)
                  setIsPickerVisible(false)
                }}
              />
            </Suspense>
          ) : (
            <EmojiPickerLoader />
          )}
        </div>
      )}
    </>
  )
}

const EmojiPickerLoader = () => (
  <div className="flex h-64 w-64 items-center justify-center rounded-lg bg-white shadow-xl">
    <Loader />
  </div>
)
