import React from 'react'

const DangerIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.75 30.3334C8.84335 30.3334 2.41669 23.9067 2.41669 16.0001C2.41669 8.09342 8.84335 1.66675 16.75 1.66675C24.6567 1.66675 31.0834 8.09342 31.0834 16.0001C31.0834 23.9067 24.6567 30.3334 16.75 30.3334ZM16.75 3.66675C9.95002 3.66675 4.41669 9.20008 4.41669 16.0001C4.41669 22.8001 9.95002 28.3334 16.75 28.3334C23.55 28.3334 29.0834 22.8001 29.0834 16.0001C29.0834 9.20008 23.55 3.66675 16.75 3.66675Z"
        fill="#FF5252"
      />
      <path
        d="M16.75 18.3334C16.2033 18.3334 15.75 17.8801 15.75 17.3334V10.6667C15.75 10.1201 16.2033 9.66675 16.75 9.66675C17.2967 9.66675 17.75 10.1201 17.75 10.6667V17.3334C17.75 17.8801 17.2967 18.3334 16.75 18.3334Z"
        fill="#FF5252"
      />
      <path
        d="M16.75 22.6668C16.5767 22.6668 16.4034 22.6268 16.2434 22.5602C16.0834 22.4935 15.9367 22.4002 15.8034 22.2802C15.6834 22.1468 15.59 22.0135 15.5234 21.8402C15.4567 21.6802 15.4167 21.5068 15.4167 21.3335C15.4167 21.1602 15.4567 20.9868 15.5234 20.8268C15.59 20.6668 15.6834 20.5202 15.8034 20.3868C15.9367 20.2668 16.0834 20.1735 16.2434 20.1068C16.5634 19.9735 16.9367 19.9735 17.2567 20.1068C17.4167 20.1735 17.5634 20.2668 17.6967 20.3868C17.8167 20.5202 17.91 20.6668 17.9767 20.8268C18.0434 20.9868 18.0834 21.1602 18.0834 21.3335C18.0834 21.5068 18.0434 21.6802 17.9767 21.8402C17.91 22.0135 17.8167 22.1468 17.6967 22.2802C17.5634 22.4002 17.4167 22.4935 17.2567 22.5602C17.0967 22.6268 16.9234 22.6668 16.75 22.6668Z"
        fill="#FF5252"
      />
    </svg>
  )
}

export default DangerIcon
