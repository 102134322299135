import React, { ComponentProps, useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import { twJoin } from 'tailwind-merge'
import Confirmation from 'shared/components/confirmation-modal'
import EllipseIcon from 'shared/icons/ellipse-icon'
import {
  AttachmentsRefInterface,
  PostAttachmentsProvider,
} from 'modules/attachments/components/context'
import AttachmentsPreview from 'modules/attachments/components/preview'
import { CommentsTypeEnum, EditorMode } from 'modules/comments/types'
import { UserInfoPopover } from 'modules/community/components/user-info-popover/user-info-popover'
import { ACTIVITY_CLEANUP_PERIOD_DURATION } from 'modules/community/const/post-consts'
import { BanPeriodUnitEnum } from 'modules/community/enums/member-enum'
import { deserializeTextHtml } from 'modules/community/hooks/slate-editor-utils'
import { PostAttachmentType } from 'modules/community/types/post-attachment'
import { escapeAttachmentLocalFields } from 'modules/community/utils/attachments'
import { RemoveRecentActivitiesCheckbox } from 'modules/post/remove-recent-activities-checkbox'
import * as postsApi from '../../../community/api/posts-api'
import * as api from '../../api/commentsApi'
import loadMoreStyles from '../../ui/CommentLoadMore.module.css'
import { formatDate, getDateLabel } from '../../utils/dateUtils'
import { useCommentsContext } from '../CommentsList'
import { Likes } from '../Likes'
import { MessageBox } from '../MessageBox'
import CommentActions from '../comment-actions'
import { CommentEditor } from '../comment-editor'
import { TextWithMentions } from '../comment-editor/deserealizeMentionToHTML'
import styles from './Comment.module.css'
import { CommentInterface, CommentProps } from './comment.types'

export const Comment = ({
  data,
  level = 0,
  parentId = data.id,
  setFirstLevelReplies,
  activeId,
  setActiveId,
  currentDate,
  pageId,
  handleDeleteReply,
  onError,
  path,
  handleEditReply,
  ...rest
}: CommentProps) => {
  const { t } = useTranslation()
  const { commentsType, setComments, commentsList, readOnly } = useCommentsContext()
  const [openedResponses, setOpenedResponses] = useState(false)
  const [replies, setReplies] = useState<CommentInterface[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const [lastCommentId, setLastCommentId] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteAndBanUserOpened, setIsDeleteAndBanUserOpened] = useState(false)
  const [removeRecentActivitiesChecked, setRemoveRecentActivitiesChecked] = useState(false)
  const attachmentsRef = useRef<AttachmentsRefInterface>(null)
  const [attachments, setAttachments] = useState<PostAttachmentType[]>([])
  const isCommentOwner = data.canDeleteComment
  const commentDate = new Date(data.updatedAt ?? data.createdAt).getTime()
  const timeDifference = currentDate - commentDate
  const wasEdited = data.updatedAt !== data.createdAt
  const isCommunityComments = commentsType === CommentsTypeEnum.Community
  const lineRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const calculateLineHeight = useCallback(() => {
    if (containerRef.current && lineRef.current) {
      const container = containerRef.current
      const line = lineRef.current
      const containerHeight = container.scrollHeight

      const lastReplyElement = container.lastElementChild?.lastElementChild as HTMLElement

      if (lastReplyElement) {
        const lastReplyHeight = lastReplyElement.offsetHeight
        const newLineHeight = containerHeight - lastReplyHeight - 31
        line.style.height = `${newLineHeight}px`
      }
    }
  }, [])

  useEffect(() => {
    if (!replies.length) {
      return
    }
    window.addEventListener('resize', calculateLineHeight)

    return () => {
      window.removeEventListener('resize', calculateLineHeight)
    }
  }, [calculateLineHeight, replies.length])

  useEffect(() => {
    calculateLineHeight()
  }, [openedResponses, calculateLineHeight, data, isEditing])

  const loadCommentReplies = async () => {
    const isFirstLoad = !replies.length
    const isPaginate = !!lastCommentId && !isEnd
    if ((!isFirstLoad || !isPaginate) && isLoading) return

    try {
      setLoading(true)
      const response = await api.getPageCommentsByLastId(
        parentId,
        commentsType,
        pageId,
        lastCommentId,
      )
      const lastPost = response.items.at(-1)
      if (lastPost) {
        setLastCommentId(lastPost.id)
      }
      if (!response.hasMore) {
        setIsEnd(true)
      }
      const repliesFn = setFirstLevelReplies || setReplies
      repliesFn((prevReplies = []) => [...prevReplies, ...response.items])
      setLoading(false)
    } catch (e) {
      setIsEnd(true)
      onError && onError(e)
    } finally {
      setLoading(false)
    }
  }

  async function loadResponses() {
    if (!openedResponses && !replies.length) {
      await loadCommentReplies()
    }
    setOpenedResponses(!openedResponses)
  }

  async function replyAndLoadResponses() {
    if (level === 0 && !replies.length) {
      await loadCommentReplies()
    }
    setActiveId(data.id)
  }

  const handleLoadMoreCommentsClick = () => {
    try {
      loadCommentReplies()
    } catch (e) {
      onError?.(e)
    }
  }

  async function handleDeleteComment() {
    const confirmation = confirm(t('comments.components.comments.delete_confirmation_label'))
    if (confirmation) {
      try {
        const response = await api.deleteComment(data.id)
        if (response.status === 200) {
          if (handleDeleteReply) {
            handleDeleteReply(data.id)
          } else {
            setComments(commentsList.filter(el => el.id !== data.id))
          }
          toast.success(t('comments.actions.delete_comment.success'))
        }
      } catch (e) {
        onError && onError(e)
      }
    }
  }

  function handleDeleteReplyComment(deletedCommentId: number) {
    setReplies(replies.filter(el => el.id !== deletedCommentId))
    setComments(
      commentsList.map(commentData =>
        commentData.id === data.id
          ? { ...commentData, totalReplies: commentData.totalReplies - 1 }
          : commentData,
      ),
    )
  }

  const handleLikeClick = (comment: CommentInterface) => async () => {
    try {
      const isLiked = comment.isLiked
      const updateFunction = isLiked ? api.unlikeComment : api.likeComment
      const delta = isLiked ? -1 : 1

      await updateFunction(comment.id)

      const updateCommentLikes = (commentData: CommentInterface) => {
        if (commentData.id === comment.id) {
          return {
            ...commentData,
            likesCount: commentData.likesCount + delta,
            isLiked: !isLiked,
          }
        }
        return commentData
      }

      if (replies?.length > 0) {
        setReplies(replies.map(updateCommentLikes))
      }

      setComments(commentsList.map(updateCommentLikes))
    } catch (e) {
      onError && onError(e)
    }
  }

  const handleEditComment = async (value: string, attachments?: PostAttachmentType[]) => {
    try {
      const { data: updatedComment } = await api.editComment(data.id, {
        text: value,
        attachments: escapeAttachmentLocalFields(attachments),
      })

      setComments(comments =>
        comments.map(comment => (comment.id === data.id ? { ...updatedComment } : comment)),
      )

      if (level > 0 && setFirstLevelReplies) {
        setFirstLevelReplies(reply =>
          reply.map(reply => (reply.id === data.id ? { ...updatedComment } : reply)),
        )
      }

      setIsEditing(false)
    } catch (e) {
      onError?.(e)
    }
  }

  const handleCancelEditing = useCallback(() => setIsEditing(false), [])

  const deletePostAndBanUser = async () => {
    if (!path) return
    await postsApi.banUser(
      path,
      data.userId,
      removeRecentActivitiesChecked
        ? {
            deleteRecentActivityOptions: {
              periodUnit: BanPeriodUnitEnum.day,
              periodDuration: ACTIVITY_CLEANUP_PERIOD_DURATION,
            },
          }
        : undefined,
    )
    const response = await api.deleteComment(data.id)
    if (response.status === 200) {
      setComments(commentsList.filter(el => el.id !== data.id))
      toast.success(t('comments.actions.delete_comment_and_ban_user.success'))
    }
  }

  return (
    <PostAttachmentsProvider
      ref={attachmentsRef}
      attachments={data.attachments}
      isEditable={isEditing}
      onChange={setAttachments}
    >
      {isEditing ? (
        <CommentEditor
          initialValue={
            deserializeTextHtml(data.text, data.mentions) as ComponentProps<
              typeof CommentEditor
            >['initialValue']
          }
          handleAddComment={handleEditComment}
          isRoot={true}
          mode={EditorMode.Edit}
          setActiveId={setActiveId}
          pageId={pageId}
          onCancel={handleCancelEditing}
          path={path}
          editableComment={data}
        />
      ) : (
        <>
          <div className="relative" ref={containerRef}>
            <div className="mt-5 flex w-full flex-row items-start gap-2.5 sm:gap-2.5">
              {openedResponses && (
                <div
                  ref={lineRef}
                  className="absolute left-[20px] top-[60px] w-[1px] bg-darkgray-100"
                ></div>
              )}
              <div className="relative">
                <img
                  className="h-10 w-10 rounded-full"
                  title={data.userName}
                  src={data.userAvatarUrl}
                />
                {level > 0 && (
                  <div className="absolute right-[39px] top-5 h-[1px] w-[30px] bg-darkgray-100"></div>
                )}
              </div>
              <div className="relative w-[calc(100%-40px)] min-w-0 grow-0 rounded-xl">
                <div className="font-AvertaPE relative rounded-xl bg-[#F0F3F6] p-[15px]">
                  <div className="mb-3 flex items-center justify-between">
                    <div className="mr-8 flex flex-col items-start">
                      {isCommunityComments ? (
                        <UserInfoPopover userId={data.userId}>
                          <span
                            className="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap text-darkblue sm:max-w-sm lg:max-w-lg"
                            title={data.userName}
                          >
                            {data.userName}
                          </span>
                        </UserInfoPopover>
                      ) : (
                        <span
                          className="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap text-darkblue sm:max-w-sm lg:max-w-lg"
                          title={data.userName}
                        >
                          {data.userName}
                        </span>
                      )}

                      <div
                        className="rtl mt-2 text-[15px] leading-5 text-lightgray-100"
                        title={formatDate(commentDate)}
                      >
                        {getDateLabel(timeDifference, t, wasEdited)}
                      </div>
                    </div>
                  </div>

                  <TextWithMentions
                    className={styles.CommentText}
                    text={data.text}
                    mentions={data.mentions}
                  />

                  <AttachmentsPreview className={twJoin(!!attachments?.length && 'pt-4')} />

                  {isCommentOwner && (
                    <CommentActions
                      commentData={data}
                      className="absolute right-3.5 top-3 justify-self-end"
                      onDelete={handleDeleteComment}
                      onDeleteAndBanUser={() => setIsDeleteAndBanUserOpened(true)}
                      onEdit={() => setIsEditing(true)}
                    />
                  )}
                </div>
                <div className="mt-[11px] flex max-h-[20px] translate-y-1 items-center gap-2 text-sm">
                  <Likes
                    handleLikeClick={
                      rest.handleLikeClick
                        ? () => rest?.handleLikeClick?.(data)
                        : handleLikeClick(data)
                    }
                    isLiked={data.isLiked}
                    likesCount={data.likesCount}
                    commentId={data.id}
                    readOnly={readOnly}
                  />
                  {!readOnly && (
                    <div
                      className="cursor-pointer font-medium text-[#98A2B3] hover:text-[#5DC2FEFF]"
                      onClick={replyAndLoadResponses}
                    >
                      {t('comments.components.comments.reply_label')}
                    </div>
                  )}
                  {data.totalReplies > 0 && <EllipseIcon />}
                  {level === 0 && !!data.totalReplies && (
                    <div
                      className="mr-2 flex cursor-pointer text-[#5DC2FEFF]"
                      onClick={loadResponses}
                    >
                      {openedResponses ? '' : data.totalReplies}{' '}
                      {openedResponses
                        ? t('comments.components.comments.responses_label_hide')
                        : t('comments.components.comments.responses_label', {
                            count: data.totalReplies,
                          })}
                      <i
                        className={`fal fa-chevron-down ${
                          openedResponses
                            ? 'ml-1.5 -rotate-180 self-center transition-transform duration-300'
                            : 'ml-1.5 transform-none self-center transition-transform duration-300'
                        }`}
                      />
                    </div>
                  )}
                </div>
                {!readOnly && activeId === data.id && (
                  <MessageBox
                    path={path}
                    parentId={parentId}
                    setActiveId={setActiveId}
                    level={level}
                    setResponses={setFirstLevelReplies || setReplies}
                    setOpenedResponses={setOpenedResponses}
                    pageId={pageId}
                    onError={onError}
                    replyInfo={{
                      profileImage: data.userAvatarUrl,
                      displayName: data.userName,
                      userId: data.userId,
                    }}
                  />
                )}
              </div>
            </div>

            {openedResponses && (
              <div className="ml-[50px] flex flex-col gap-5">
                {replies.map(comment => (
                  <Comment
                    key={comment.id}
                    handleLikeClick={handleLikeClick(comment)}
                    data={comment}
                    level={1}
                    parentId={parentId}
                    setActiveId={setActiveId}
                    activeId={activeId}
                    currentDate={currentDate}
                    pageId={pageId}
                    handleDeleteReply={handleDeleteReplyComment}
                    onError={onError}
                    path={path}
                    setFirstLevelReplies={setFirstLevelReplies || setReplies}
                  />
                ))}
                {!isEnd && (
                  <div
                    className={loadMoreStyles.CommentLoadMore}
                    onClick={handleLoadMoreCommentsClick}
                  >
                    {t('comments.components.comments.load_more_label')}
                  </div>
                )}
              </div>
            )}

            {isDeleteAndBanUserOpened && (
              <Confirmation
                onConfirm={deletePostAndBanUser}
                onCloseModal={() => setIsDeleteAndBanUserOpened(false)}
                confirmationContent={
                  <RemoveRecentActivitiesCheckbox
                    label={t('settings.form.delete_comment_and_ban_user_confirmation.label')}
                    memberDisplayName={data.userName}
                    checked={removeRecentActivitiesChecked}
                    onCheck={() => setRemoveRecentActivitiesChecked(prev => !prev)}
                  />
                }
                errorMessageMap={{
                  unprocessableContentError: 'community.error.error_user_already_banned',
                }}
              />
            )}
          </div>
        </>
      )}
    </PostAttachmentsProvider>
  )
}
