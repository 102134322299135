import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import useUser from 'shared/hooks/use-user'
import * as api from '../../api/commentsApi'
import loadMoreStyles from '../../ui/CommentLoadMore.module.css'
import { MessageBox } from '../MessageBox'
import { Comment } from '../comment'
import { CommentInterface } from '../comment/comment.types'
import { CommentsContextType, CommentsProps } from './CommentsList.types'

export const CommentsContext = createContext({} as CommentsContextType)

export const useCommentsContext = () => useContext(CommentsContext)

function CommentsList({
  commentsType,
  shouldFetch = true,
  pageId,
  path,
  locale = 'en',
  readOnly = false,
  onError,
  rollbar,
}: CommentsProps) {
  const { t } = useTranslation()
  const { user } = useUser()
  const [activeId, setActiveId] = useState(0)
  const [comments, setComments] = useState<CommentInterface[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isEnd, setIsEnd] = useState(true)
  const [lastCommentId, setLastCommentId] = useState(0)

  const currentDate = Date.now()

  const loadComments = useCallback(async () => {
    try {
      setLoading(true)
      const data = await api.getPageCommentsByLastId(null, commentsType, pageId, lastCommentId)
      const lastPost = data.items.at(-1)
      if (lastPost) {
        setLastCommentId(lastPost.id)
      }
      if (!data.hasMore) {
        setIsEnd(true)
      } else {
        setIsEnd(false)
      }
      setComments((prevComments = []) => {
        const allComments = prevComments.concat(data.items)
        const uniqueIds = new Set(allComments.map(({ id }) => id))
        // a hack to find last
        const reversed = allComments.reverse()
        return Array.from(uniqueIds, id => reversed.find(comment => comment.id === id)!)
      })
      setLoading(false)
    } catch (e) {
      setIsEnd(true)
      onError && onError(e)
      // @ts-ignore
      rollbar?.error('Comments error', e)
      setLoading(false)
    }
  }, [shouldFetch, pageId, commentsType, lastCommentId])

  useEffect(() => {
    if (shouldFetch) {
      loadComments()
    }
  }, [shouldFetch])

  const handleLoadMoreCommentsClick = async () => {
    if (!isEnd && !isLoading) {
      try {
        loadComments()
      } catch (e) {
        onError && onError(e)
      }
    }
  }

  return (
    <CommentsContext.Provider
      value={{
        commentsType: commentsType,
        commentsList: comments,
        setComments: setComments,
        readOnly: readOnly,
        rollbar,
        user,
      }}
    >
      <div className="flex flex-col items-stretch gap-7">
        {!readOnly && activeId === 0 && (
          <MessageBox path={path} parentId={null} pageId={pageId} onError={onError} />
        )}
        {comments &&
          comments.map((comment: CommentInterface) => (
            <Comment
              key={comment.id}
              data={comment}
              level={0}
              setActiveId={setActiveId}
              activeId={activeId}
              currentDate={currentDate}
              pageId={pageId}
              onError={onError}
              path={path}
            />
          ))}
        {!isEnd && (
          <div className={loadMoreStyles.CommentLoadMore} onClick={handleLoadMoreCommentsClick}>
            {t('comments.components.comments.load_more_label')}
          </div>
        )}
      </div>
    </CommentsContext.Provider>
  )
}

export default CommentsList
