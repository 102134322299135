import React, { Fragment, useState } from 'react'
import { ReactEditor, useSlate } from 'slate-react'
import { Emoji } from 'emoji-mart'
import { twMerge } from 'tailwind-merge'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import EmbeddedVideoAttachment from 'modules/attachments/components/embedded-video-attachment'
import { GifAttachment } from 'modules/attachments/components/gif-attachment'
import UploadAttachment from 'modules/attachments/components/upload-attachment'
import { EmojiPicker } from 'modules/comments/components/comment-editor/elements/emoji-picker/emoji-picker'
import { toolbarGroups } from '../../../utils/constants'
import LinkButton from '../elements/Link/LinkButton'
import { LinkModal } from '../elements/Link/link-modal'
import MentionButton from '../elements/Mention/MentionButton'
import { insertText } from '../utils/serialize'

export interface ToolbarProps {
  className?: string
  path?: string
}

export const Toolbar = ({ path, className = '' }: ToolbarProps) => {
  const editor = useSlate()
  const [linkIsEditing, setLinkIsEditing] = useState(false)

  const handleMentionClick = () => {
    insertText(editor, '@')
    ReactEditor.focus(editor) // Focus the editor after inserting the mention
  }

  const handleAddEmoji = (emoji: typeof Emoji.Props) => {
    insertText(editor, emoji.native)
    ReactEditor.focus(editor)
  }

  return (
    <div className={twMerge('flex items-center gap-3', className)}>
      <UploadAttachment
        className="group"
        iconClassName="h-5 w-5 text-lightgray group-hover:text-darkblue"
      />
      <EmbeddedVideoAttachment />
      <GifAttachment />
      {!linkIsEditing && (
        <>
          {toolbarGroups.map(element => {
            switch (element.type) {
              case 'link':
                return <LinkButton key={element.id} setLinkIsEditing={setLinkIsEditing} />
              case 'mention':
                return path && <MentionButton key={element.id} onClick={handleMentionClick} />
              default:
                return <div />
            }
          })}
          <EmojiPicker onEmojiSelect={handleAddEmoji} />
        </>
      )}
      <LinkModal setLinkIsEditing={setLinkIsEditing} linkIsEditing={linkIsEditing} />
    </div>
  )
}
