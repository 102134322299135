import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Editable, Slate, withReact } from 'slate-react'
import { isKeyHotkey } from 'is-hotkey'
import { useTranslation } from 'next-i18next'
import { Descendant, Editor, Range, Transforms, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { twJoin, twMerge } from 'tailwind-merge'
import { useDebouncedValue } from 'shared/hooks/use-debounced-value'
import { isAuthorizedUser } from 'shared/hooks/use-user'
import { SendIcon } from 'shared/icons/send-icon'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import AttachmentsPreview from 'modules/attachments/components/preview'
import { getSuggestionsList } from 'modules/comments/api/commentsApi'
import { EditorMode } from 'modules/comments/types'
import { ParagraphElement } from 'modules/community/types/CustomEditor'
import { PostAttachmentType } from 'modules/community/types/post-attachment'
import { UserInfoPopover } from '../../../community/components/user-info-popover/user-info-popover'
import useIsUserMember from '../../../community/hooks/use-is-user-member'
import { useCommentsContext } from '../CommentsList'
import { CommentInterface, Suggestion } from '../comment/comment.types'
import { MentionSuggestions } from './elements/SuggestionMention/SuggestionMention'
import { Element } from './elements/element'
import { Leaf } from './elements/leaf'
import withLinks from './plugins/withLinks'
import { withMentions } from './plugins/withMentions'
import { Toolbar } from './toolbar'
import { insertMention } from './utils/mention'
import { serializeHTML } from './utils/serialize'

export interface CommentEditorProps {
  handleAddComment: (value: string, attachments?: PostAttachmentType[]) => void
  setActiveId: (id: number) => void
  isRoot: boolean
  path?: string
  pageId: number
  initialValue?: Descendant[]
  mode?: EditorMode
  onCancel?: () => void
  className?: string
  editableComment?: CommentInterface
}

export const CommentEditor = ({
  handleAddComment,
  setActiveId,
  isRoot,
  path,
  pageId,
  initialValue,
  mode = EditorMode.Add,
  onCancel,
  className = '',
  editableComment,
}: CommentEditorProps) => {
  const { member } = useIsUserMember()
  const { t } = useTranslation()
  const { user } = useCommentsContext()
  const [serializedTextHtml, setSerializedTextHtml] = useState('')
  const editor = useMemo(() => withHistory(withLinks(withMentions(withReact(createEditor())))), [])
  const { attachments, isUploading } = usePostAttachmentsContext()

  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)
  const [target, setTarget] = useState<Range | null>(null)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 300)

  useEffect(() => {
    if (path) {
      setSuggestionsLoading(true)
      getSuggestionsList(path, pageId, debouncedSearch)
        .then(res => {
          setSuggestions(res)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setSuggestionsLoading(false)
        })
    }
  }, [debouncedSearch])

  const [value, setValue] = useState<Descendant[]>(
    initialValue || [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ],
  )

  const renderElement = useCallback((props: any) => <Element {...props} />, [])

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />
  }, [])

  const handleSendComment = () => {
    if (serializedTextHtml) {
      handleAddComment(serializedTextHtml, attachments)

      if (mode === EditorMode.Edit) return

      Transforms.select(editor, Editor.start(editor, []))
      editor.history = { redos: [], undos: [] }
      const emptyParagraph: ParagraphElement = {
        type: 'paragraph',
        children: [{ text: '' }],
      }
      editor.children = [emptyParagraph]
    }
  }

  const handleCancel = () => {
    if (onCancel) return onCancel()

    setActiveId(0)
  }

  useEffect(() => {
    const textHtml = serializeHTML(editor)
    if (!textHtml) {
      setSerializedTextHtml('')
    } else {
      setSerializedTextHtml(textHtml)
    }
  }, [value])

  const updateEditorContent = (value: Descendant[]) => {
    setValue(value)
    const { selection } = editor

    // NOTE: This code for a display list of mentions when typing '@'
    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection)
      const wordBefore = Editor.before(editor, start, { unit: 'word' })
      const before = wordBefore && Editor.before(editor, wordBefore)
      const beforeRange = before && Editor.range(editor, before, start)
      const beforeText = beforeRange && Editor.string(editor, beforeRange)
      const beforeMatch = beforeText && beforeText.match(/(?:^|\s)@([^\s@]*)(\s|$)/)
      const after = Editor.after(editor, start)
      const afterRange = Editor.range(editor, start, after)
      const afterText = Editor.string(editor, afterRange)
      const afterMatch = afterText.match(/^(\s|$)/)

      const twoCharsBefore =
        wordBefore &&
        Editor.before(
          editor,
          { ...wordBefore, offset: wordBefore.offset },
          { unit: 'character', distance: 2 },
        )
      const twoCharsRange = twoCharsBefore && Editor.range(editor, twoCharsBefore, start)
      const twoCharsText = twoCharsRange && Editor.string(editor, twoCharsRange)

      const isEmptyBeforeAtRegular = new RegExp(/(?:^|\s)@/)
      const isEmptyBeforeAt = isEmptyBeforeAtRegular.test(twoCharsText || '')

      if (beforeMatch?.[1] && afterMatch && isEmptyBeforeAt) {
        // NOTE: This is for contains mention '@asd'
        setTarget(beforeRange || twoCharsRange || null)
        setSearch(beforeMatch[1])
        return
      } else {
        // NOTE: This is for empty mention '@'
        const pointBefore = Editor.before(editor, start, { distance: 2 })
        const pointRange = pointBefore && Editor.range(editor, pointBefore, start)
        const characterBefore = pointRange && Editor.string(editor, pointRange)

        const pointNormal = Editor.before(editor, start)
        const pointNormalRange = pointNormal && Editor.range(editor, pointNormal, start)

        if (characterBefore === ' @' || characterBefore === '@') {
          setTarget(pointNormalRange || pointRange || beforeRange || null)
          setSearch('')
          return
        }
      }
    }

    setTarget(null)
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    const { selection } = editor

    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    // You may wish to customize this further to only use unit:'offset' in specific cases.
    // Reference from official Slate example: https://github.com/ianstormtaylor/slate/blob/6bc57583587d88b26fb03e054511b6d7ac23ef7c/site/examples/ts/inlines.tsx#L74
    if (selection && Range.isCollapsed(selection)) {
      const { nativeEvent } = event
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
      }
    }
  }

  return (
    <Slate editor={editor} initialValue={value} onChange={updateEditorContent}>
      <div className={className}>
        <div className="mt-5 flex flex-row items-start gap-2.5 sm:gap-2.5">
          {isAuthorizedUser(user) && (
            <img className="mt-2 h-10 w-10 rounded-full" src={user.avatarUrl} />
          )}

          <div className="relative w-[calc(100%-40px)] min-w-0 grow-0 rounded-xl border-[#E7E7E7] bg-[#F0F3F6] p-5 pb-12 pr-14">
            {isAuthorizedUser(user) && member && mode === EditorMode.Edit && (
              <div className="absolute left-[18px] top-3.5 z-[1] flex items-center gap-2">
                <UserInfoPopover userId={member.userId}>
                  <span
                    className="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-darkblue md:max-w-sm"
                    title={editableComment?.userName}
                  >
                    {editableComment?.userName}
                  </span>
                </UserInfoPopover>
              </div>
            )}
            <Editable
              className={twMerge(
                'text-darkblue [&_a:hover]:underline [&_a]:text-[#2e6ef4] [&_a]:no-underline',
                mode === EditorMode.Edit && 'pt-7',
              )}
              placeholder={t('comments.components.comments.input_placeholder_label')}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              style={{ whiteSpace: 'break-spaces' }}
              onKeyDown={onKeyDown}
            />
            <AttachmentsPreview className={twJoin(!!attachments?.length && 'pt-4')} />
            <Toolbar className="absolute bottom-2.5 left-3.5" path={path} />
            <button
              className="absolute right-6 top-5 flex h-6 w-6 items-center justify-center text-blue disabled:text-[#98A2B3]"
              onClick={handleSendComment}
              disabled={!serializedTextHtml || serializedTextHtml === '<br>' || isUploading}
            >
              <SendIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        {path && target && (suggestionsLoading || suggestions.length > 0) && (
          <MentionSuggestions
            target={target}
            editor={editor}
            items={suggestions}
            isLoading={suggestionsLoading}
            onSelect={char => {
              if (target) {
                insertMention(
                  editor,
                  {
                    displayName: char.displayName,
                    id: char.userId,
                    profileImageUrl: char.profileImageUrl,
                  },
                  target,
                )
                setTarget(null)
              }
            }}
          />
        )}
        {isRoot && (
          <div className="mt-2.5 flex items-center justify-between">
            <button
              className="z-[1] ml-[50px] flex min-h-[36px] w-auto min-w-[80px] cursor-pointer select-none items-center justify-center self-center rounded border border-[#8995b0] bg-white px-5 text-center text-sm font-bold text-[#8995b0] outline-0"
              disabled={!serializedTextHtml}
              onClick={handleCancel}
            >
              {t('comments.components.comments.cancel_label')}
            </button>
          </div>
        )}
      </div>
    </Slate>
  )
}
