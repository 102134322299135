export const formatDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  }
  return new Intl.DateTimeFormat('en-GB', options).format(date)
}

export const getDateLabel = (interval: number, translate: any, wasEdited?: boolean) => {
  const newInterval = interval / 1000
  const years = Math.round(newInterval / 60 / 60 / 24 / 365)
  const months = Math.round(newInterval / 60 / 60 / 24 / 30)
  const weeks = Math.round(newInterval / 60 / 60 / 24 / 7)
  const day = Math.round(newInterval / 60 / 60 / 24)
  const hour = Math.round((newInterval / 60 / 60) % 24)
  const minute = Math.round((newInterval / 60) % 60)
  const second = Math.round(newInterval % 60)
  const prefix = wasEdited ? ` • (${translate('comments.state.edited')})` : ''


  const caption = (() => {
    if (years) {
      return `${years}y`
    } else if (months) {
      return `${months}mo`
    } else if (weeks) {
      return `${weeks}w`
    } else if (day) {
      return `${day}d`
    } else if (hour) {
      return `${hour}h`
    } else if (minute) {
      return `${minute}mins`
    } else if (second) {
      return `${translate('comments.components.comments.just_now_label')}`
    }
    return 'just now'
  })()

  return `${caption} ${prefix}`
}
