import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, UseFormSetError, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Emoji } from 'emoji-mart'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useSWR, { useSWRConfig } from 'swr'
import { httpClient } from '../../../../shared/api/http-client'
import Modal from '../../../../shared/components/modal'
import { BadRequest } from '../../../../shared/errors/bad-request'
import { InternalError } from '../../../../shared/errors/internal-error'
import PlusIcon from '../../../../shared/icons/plus-icon'
import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import PrimaryButton from 'shared/components/primary-button'
import { usePostAttachmentsContext } from 'modules/attachments/components/context'
import { generatePathFromName } from 'modules/community/hooks/path-utils'
import { PostAttachmentType } from 'modules/community/types/post-attachment'
import { escapeAttachmentLocalFields } from 'modules/community/utils/attachments'
import { PostStatusEnum } from '../../enums/post-status-enum'
import { useCommunityWithTopics } from '../../hooks/use-community-with-topics'
import { PostInterface } from '../../types/post-interface'
import PostAttachments from './components/attachments'
import AddPostContentEditor, { EditorRef } from './components/content'
import AddPostInputField from './components/input-field'
import AddPostTopicSelect from './components/topic-select'

interface FormValues {
  title: string
  path: string
  content: string
  topic?: number
  attachments?: PostAttachmentType[]
}

const defaultValues: FormValues = {
  title: '',
  path: '',
  content: '',
}

type ErrorFields = Parameters<UseFormSetError<FormValues>>[0]

const errorFields: ErrorFields[] = ['title', 'path', 'topic', 'content', 'root']

interface AddPostProps {
  onAdd?: (post: PostInterface) => void
}

function AddPost({ onAdd }: AddPostProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { topicPath } = router.query
  const [opened, setOpened] = useState(false)
  const { data: community, isValidating } = useCommunityWithTopics()
  const { mutate } = useSWRConfig()
  const { mutate: mutatePostPending } = useSWR<PostInterface[]>('pending-posts')
  const { isUploading } = usePostAttachmentsContext()

  const formMethods = useForm<FormValues>({ defaultValues })
  const editorRef = useRef<EditorRef>(null)

  function convertNameToPath(e: React.FocusEvent<HTMLInputElement>) {
    if (!e.target.value) {
      formMethods.setValue('path', generatePathFromName(formMethods.getValues('title')))
    }
  }

  const handleAddEmoji = (emoji: typeof Emoji.Props) => {
    editorRef.current?.insertEmoji(emoji.native)
  }

  const clearForm = useCallback(() => {
    formMethods.reset(defaultValues)
    editorRef.current?.clear()
  }, [])

  const clearErrors = useCallback(() => {
    formMethods.clearErrors()
    editorRef.current?.clearError()
  }, [])

  const setError = useCallback(
    (name: ErrorFields, error?: string) => formMethods.setError(name, { message: error }),
    [],
  )

  const onSubmit: SubmitHandler<FormValues> = async (formData: FormValues) => {
    if (!community) {
      return
    }

    clearErrors()

    const { title, path, content, topic, attachments } = formData
    try {
      const { data } = await httpClient.post<PostInterface>(
        `${COMMUNITY_API_PREFIX}/${community.path}/post`,
        {
          title,
          path,
          content,
          topic,
          attachments: escapeAttachmentLocalFields(attachments),
        },
      )
      if (data.status === PostStatusEnum.Approved) {
        const selectedTopic = community?.topics.find(t => t.id === topic)
        const mutateKey =
          selectedTopic && selectedTopic.path === topicPath ? 'topic-posts' : 'posts'
        await mutate<PostInterface[]>(mutateKey, prev => (prev ? [data, ...prev] : [data]))
      } else {
        toast.success(t('community.add_post.sent_to_approval_message'))
        await mutatePostPending((prevPosts = []) => [...prevPosts, data], false)
      }

      onAdd?.(data)
      setOpened(false)
      clearForm()
    } catch (e) {
      if (e instanceof BadRequest) {
        errorFields.forEach(name => {
          const error = e as BadRequest
          if (error.errors?.fields?.[name]) {
            setError(name, error.errors.fields[name].join('\n'))
          }

          if (name === 'root' && error.errors?.common) {
            setError(name, error.errors.common.join('\n'))
          }
        })
      } else if (e instanceof InternalError) {
        setError('root', t('core.error.internal_error_message'))
      }
    }
  }

  useEffect(() => {
    if (!opened) {
      clearForm()
      clearErrors()
    }
  }, [opened, clearForm, clearErrors])

  if (!community || isValidating) {
    return <div className="mb-10 h-28 animate-pulse rounded-lg bg-gray" />
  }

  const commonError = formMethods.formState.errors.root?.message

  return (
    <>
      <div className="mb-8 flex flex-col items-end bg-white p-5 lg:rounded-lg">
        <button
          className="h-14 w-full cursor-pointer resize-none overflow-hidden rounded-md border border-bluegray/40 p-4 text-start focus:outline-none"
          onClick={() => setOpened(true)}
        >
          {t('home.add_post.text_area.placeholder')}
        </button>
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('settings.create_post.modal.title')}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <AddPostInputField name="title" maxLength={256} label={t('home.add_post.title')} />
            <AddPostInputField
              name="path"
              maxLength={32}
              onFocus={convertNameToPath}
              label={t('home.add_post.path')}
            />
            <AddPostTopicSelect />
            <AddPostContentEditor ref={editorRef} />
            <PostAttachments onAddEmoji={handleAddEmoji} />
            <div className="flex justify-end">
              <PrimaryButton className="ml-auto" type="submit" disabled={isUploading}>
                <PlusIcon />
                {t('home.add_post.button.title')}
              </PrimaryButton>
            </div>
            <div>{commonError && <p className="mt-2 text-sm text-red">{commonError}</p>}</div>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default AddPost
