import React, {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { twJoin, twMerge } from 'tailwind-merge'
import Sortable from 'shared/components/sortable'
import ArrowLeftIcon from 'shared/icons/arrow-left-icon'
import { usePostAttachmentsContext } from '../context'
import AttachmentPreviewItem from './preview-item'

interface AttachmentsPreviewProps {
  className?: string
}

export default function AttachmentsPreview({ className }: AttachmentsPreviewProps) {
  const { attachments, reorder, isEditable } = usePostAttachmentsContext()

  const attachmentIds = attachments?.filter(a => !!a.id).map(a => a.id) ?? []

  const isTouchDevice = useMemo(() => {
    return (
      typeof window !== 'undefined' && ('ontouchstart' in window || navigator?.maxTouchPoints > 0)
    )
  }, [])

  const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  })
  const allSensors = useSensors(pointerSensor, keyboardSensor)
  const sensors = isTouchDevice || !isEditable ? [] : allSensors

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [scrollState, setScrollState] = useState({ canScrollLeft: false, canScrollRight: false })

  useEffect(() => {
    if (isTouchDevice) return

    const el = scrollContainerRef.current
    if (!el) return

    const updateScrollState = () => {
      setScrollState({
        canScrollLeft: el.scrollLeft > 0,
        canScrollRight: el.scrollLeft + el.clientWidth < el.scrollWidth,
      })
    }

    updateScrollState()
    el.addEventListener('scroll', updateScrollState)
    window.addEventListener('resize', updateScrollState)

    return () => {
      el.removeEventListener('scroll', updateScrollState)
      window.removeEventListener('resize', updateScrollState)
    }
  }, [attachments, isTouchDevice])

  const scrollBy = (distance: number) => {
    scrollContainerRef.current?.scrollBy({ left: distance, behavior: 'smooth' })
  }

  const handleScrollLeft = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    scrollBy(-200)
  }

  const handleScrollRight = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    scrollBy(200)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    reorder(active.id as number, over?.id as number)
  }

  return (
    <div className="relative">
      {!isTouchDevice && scrollState.canScrollLeft && (
        <ScrollButton onClick={handleScrollLeft} direction="left" />
      )}

      {!isTouchDevice && scrollState.canScrollRight && (
        <ScrollButton onClick={handleScrollRight} direction="right" />
      )}

      <div className="hidden-scrollbar max-w-full overflow-auto" ref={scrollContainerRef}>
        <ul className={twMerge('customScroll flex flex-row gap-3', className)}>
          <DndContext
            onDragEnd={handleDragEnd}
            sensors={sensors}
            modifiers={[restrictToHorizontalAxis]}
          >
            <SortableContext items={attachmentIds}>
              {attachments?.map(attachment => (
                <Wrapper key={attachment.id} attachment={attachment}>
                  <AttachmentPreviewItem attachment={attachment} />
                </Wrapper>
              ))}
            </SortableContext>
          </DndContext>
        </ul>
      </div>
    </div>
  )
}

const Wrapper = ({
  children,
  attachment,
}: PropsWithChildren & ComponentProps<typeof AttachmentPreviewItem>) => {
  if (!attachment.id) return <li>{children}</li>

  return (
    <Sortable id={attachment.id} data={attachment}>
      {children}
    </Sortable>
  )
}

const ScrollButton = ({
  onClick,
  direction,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  direction: 'left' | 'right'
}) => (
  <div
    className={twJoin(
      'absolute top-1/2 z-30 -translate-y-1/2',
      direction === 'left' ? 'left-2' : 'right-2',
    )}
  >
    <button
      onClick={onClick}
      className="group relative flex h-10 w-10 items-center justify-center rounded-full bg-white shadow-md hover:border-2 hover:border-darkblue2"
    >
      <ArrowLeftIcon
        className={twJoin(
          'stroke-blue-300 group-hover:stroke-darkblue2',
          direction === 'right' && 'rotate-180',
        )}
      />
    </button>
  </div>
)
