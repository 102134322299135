import { twMerge } from 'tailwind-merge'
import {
  AttachmentUploadStatusEnum,
  VideoUploadAttachment,
} from 'modules/community/types/post-attachment'

interface VideoAttachmentPreviewProps {
  className?: string
  attachment: VideoUploadAttachment
}

const VideoAttachmentPreview = ({ attachment, className }: VideoAttachmentPreviewProps) => {
  return (
    <div
      className={twMerge(
        'h-full w-full overflow-hidden rounded-lg',
        attachment.status === AttachmentUploadStatusEnum.Uploading && 'bg-slate-100',
        className,
      )}
    >
      <video className="h-full w-full object-cover" key={attachment.id} src={attachment.url} />
    </div>
  )
}

export default VideoAttachmentPreview
