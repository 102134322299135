import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { useRollbar } from '@rollbar/react'
import React, { Fragment } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import { LocaleEnum } from '../../../../shared/enums/locale-enum'
import useDomainAssets from '../../../../shared/hooks/use-domain-assets'
import useUser from '../../../../shared/hooks/use-user'
import CloseThinIcon from 'shared/icons/close-thin-icon'
import CommentOutlinedIcon from 'shared/icons/comment-outlined-icon'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import AttachmentPreviewItem from 'modules/attachments/components/preview/preview-item'
import { CommentsList } from 'modules/comments/components'
import { CommentsTypeEnum } from 'modules/comments/types'
import { usePostPermissions } from 'modules/community/hooks/use-post-permissions'
import { PostInterface } from 'modules/community/types/post-interface'
import { timeSince } from 'modules/post/utils/time-utils'
import { PostAnnounceSkeleton } from '.'
import useIsUserMember from '../../hooks/use-is-user-member'
import { TextWithMentions } from '../editor/utils/deserealizeMentionToHTML'
import LikeButton from '../like/like-button'
import PostModalEdit from '../post-modal-edit'
import { PostAuthorInfo } from './author-info'

export interface BaseModalProps {
  className?: string
  opened: boolean
  onClose: () => void
  deletePost?: () => Promise<void>
  deletePostAndBanUser?: (removeRecentActivitiesChecked: boolean) => Promise<void>
  post: PostInterface
  handleLikeClick?: () => void
  dashboardLocale?: LocaleEnum
  commentsCount?: number
  topicPath: string
}

function PostModal({
  className,
  onClose,
  opened,
  post,
  handleLikeClick,
  dashboardLocale,
  topicPath,
  commentsCount,
  deletePost,
  deletePostAndBanUser,
}: BaseModalProps) {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const router = useRouter()
  const { communityPath } = router.query
  const { isUserMember } = useIsUserMember()
  const { domainAssets } = useDomainAssets()
  const { isPostManageable } = usePostPermissions(post)
  const { user, loading: isUserLoading } = useUser()

  if (!user || isUserLoading) {
    return <PostAnnounceSkeleton />
  }

  return (
    <PostAttachmentsProvider attachments={post.attachments}>
      <Transition show={opened} as={Fragment}>
        <Dialog className="fixed z-[20]" open={opened} onClose={onClose}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/60 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full w-full items-end justify-center text-center sm:items-center">
              <div className="relative w-full max-w-[840px]">
                <button
                  className="absolute right-0 top-5 z-[1001] ml-[15px] mt-11 hidden items-center justify-center lg:flex xl:flex 2xl:flex"
                  onClick={onClose}
                >
                  <CloseThinIcon className="fill-white" />
                </button>
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <DialogPanel
                    className={`relative min-h-screen w-full max-w-[800px] transform bg-white text-left shadow-xl transition-all md:mt-16 md:min-h-fit md:rounded-lg ${
                      className || ''
                    }`}
                  >
                    <div className="sticky top-0 z-[100] flex h-12 w-full items-center border-b border-gray-300/70 bg-white px-10 py-2 md:hidden">
                      <div className="flex w-full min-w-0">
                        <div className="flex min-w-0 items-center gap-2 text-darkblue">
                          <button className="flex items-center justify-center" onClick={onClose}>
                            <CloseThinIcon className="fill-bluegray" />
                          </button>
                          <img
                            src={domainAssets?.logoUrl}
                            alt="logo"
                            className="h-6 w-6 rounded-full"
                            width={24}
                            height={24}
                          />
                          <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
                            {post.title}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mx-auto max-w-[calc(100%-80px)] border-b border-[#E7E7E7] py-[25px] font-avertaPE">
                      <div className="mb-5 flex items-center gap-2">
                        <PostAuthorInfo author={post.author} userId={post.userId}>
                          <div className="text-base text-lightgray-100">
                            {timeSince(new Date(post.createdAt), dashboardLocale)}{' '}
                            {t('community.pages_on')}{' '}
                            <Link className="font-semibold text-darkblue" href={topicPath} shallow>
                              #{post.topic.name}
                            </Link>
                          </div>
                        </PostAuthorInfo>
                        {isPostManageable && (
                          <PostModalEdit
                            className="z-10"
                            post={post}
                            deletePost={deletePost}
                            deletePostAndBanUser={deletePostAndBanUser}
                          />
                        )}
                      </div>
                      <p className="community-post-title sm:text-lg md:text-lg lg:text-[22px]">
                        {post.title}
                      </p>
                      <TextWithMentions
                        className={twMerge('mt-5 overflow-hidden text-base text-darkblue/90')}
                        text={post.content}
                        mentions={post.mentions}
                      />
                      {!!post?.attachments?.length && (
                        <div className="mt-[30px] flex flex-row gap-3 overflow-x-auto">
                          {post.attachments.map(attachment => (
                            <AttachmentPreviewItem
                              className="h-64 w-64 max-w-full shrink-0 md:h-80 md:w-80"
                              key={attachment.dataFileId}
                              attachment={attachment}
                              size="original"
                            />
                          ))}
                        </div>
                      )}
                      <div className="mt-[22px] flex items-center gap-[10px]">
                        {handleLikeClick && (
                          <LikeButton
                            isLiked={post.isLiked}
                            likesCount={post.likesCount}
                            handleLikeClick={handleLikeClick}
                            postId={post.id}
                          />
                        )}
                        <div className="flex items-center gap-2 rounded-md px-1.5 py-2">
                          <CommentOutlinedIcon className="h-5 w-5 text-bluegray transition-colors group-hover:text-blue" />
                          {commentsCount || ''}
                        </div>
                      </div>
                    </div>
                    <div className="min-h-[48px] px-[40px] py-5">
                      <div className="mb-3 mt-[30px] font-avertaPE text-xl font-semibold text-darkblue md:mb-5">
                        {t('post_details.comments')}
                      </div>
                      {user && (
                        <CommentsList
                          path={String(communityPath)}
                          commentsType={CommentsTypeEnum.Community}
                          onError={() => toast.error(t('comments.error.error-loading-comments'))}
                          pageId={post.id}
                          locale={user.dashboardLocale}
                          readOnly={!isUserMember}
                          rollbar={rollbar}
                        />
                      )}
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </PostAttachmentsProvider>
  )
}

export default PostModal
