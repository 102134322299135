import ReactPlayer from 'react-player'
import { oembed as loomOembed, validate } from '@loomhq/loom-embed'
import {
  NOEMBED_BASE_URL,
  WISTIA_OEMBED_API_URL,
  WISTIA_URL_REGEXP,
} from 'modules/attachments/const/attachments-consts'
import { OEmbedInterface } from 'modules/attachments/types/attachment-types'
import { baseFetcher } from 'modules/comments/api/http-client'

export const isValidEmbeddedVideoUrl = (url: string) =>
  validate.isLoomUrl(url) || ReactPlayer.canPlay(url)

export const fetchOembed = (url: string) => {
  const isLoomUrl = validate.isLoomUrl(url)
  const isWistiaUrl = WISTIA_URL_REGEXP.test(url)

  if (isLoomUrl) {
    return loomOembed(url)
  } else if (isWistiaUrl) {
    return baseFetcher<OEmbedInterface>(`${WISTIA_OEMBED_API_URL}?url=${url}`)
  }

  return baseFetcher<OEmbedInterface>(`${NOEMBED_BASE_URL}?url=${url}`)
}
