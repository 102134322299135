import { twMerge } from 'tailwind-merge'
import { GifAttachment } from 'modules/community/types/post-attachment'

interface GifAttachmentPreviewProps {
  className?: string
  attachment: GifAttachment
}

export const GifAttachmentPreview = ({ attachment, className }: GifAttachmentPreviewProps) => (
  <div
    className={twMerge(
      'h-full w-full overflow-hidden rounded-lg',
      !attachment.previewUrl && 'bg-slate-100',
      className,
    )}
  >
    {!!attachment.previewUrl && (
      <img
        src={attachment.previewUrl}
        alt=""
        className="h-full w-full object-cover transition-all group-hover:scale-110"
      />
    )}
  </div>
)
