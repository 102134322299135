import { Dialog, DialogPanel, TransitionChild } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { useSlate } from 'slate-react'
import { useTranslation } from 'next-i18next'
import { SecondaryButton } from 'shared/components/button'
import FormInput from 'shared/components/form-input'
import PrimaryButton from 'shared/components/primary-button'
import CloseThinIcon from 'shared/icons/close-thin-icon'
import { insertLink } from '../../utils/link'

export const LinkModal = ({
  linkIsEditing,
  setLinkIsEditing,
}: {
  linkIsEditing: boolean
  setLinkIsEditing: (value: boolean) => void
}) => {
  const { t } = useTranslation()
  const editor = useSlate()
  const [url, setUrl] = useState('')

  const handleSaveLink = () => {
    url && insertLink(editor, url)
    setLinkIsEditing(false)
    setUrl('')
  }
  const closeOnEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSaveLink()
    }
  }

  const onCloseDialog = () => {
    setLinkIsEditing(false)
    setUrl('')
  }

  return (
    <Dialog className="fixed z-[20]" open={linkIsEditing} onClose={onCloseDialog}>
      <TransitionChild
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black/60 transition-opacity" />
      </TransitionChild>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full w-full items-center justify-center text-center">
          <div className="relative w-full max-w-[640px]">
            <button
              className="absolute right-0 top-0 z-[1001] ml-[15px] hidden items-center justify-center sm:flex md:top-5 md:mt-11"
              onClick={onCloseDialog}
            >
              <CloseThinIcon className="fill-white" />
            </button>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={`relative w-full transform p-2 text-left transition-all sm:w-[calc(100%-40px)] md:mt-16 md:min-h-fit md:rounded-lg md:p-0`}
              >
                <div className="flex flex-col items-start gap-3.5 rounded-md bg-white p-5 sm:gap-8">
                  <p className="text-base font-bold text-darkblue sm:text-2xl">
                    {t('comments.components.comments.add_link_title')}
                  </p>
                  <FormInput
                    className="w-full"
                    placeholder={t('comments.components.comments.link_menu_placeholder')}
                    onKeyDown={closeOnEnterKey}
                    value={url}
                    onChange={e => setUrl(e.target.value)}
                  />
                  <div className="flex w-full items-center justify-end gap-2.5">
                    <SecondaryButton className="w-full px-7 sm:w-fit" onClick={onCloseDialog}>
                      {t('comments.components.comments.add_link_cancel')}
                    </SecondaryButton>
                    <PrimaryButton className="w-full p-1 px-8 sm:w-fit" onClick={handleSaveLink}>
                      {t('comments.components.comments.add_link_save')}
                    </PrimaryButton>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
