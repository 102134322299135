import { twMerge } from 'tailwind-merge'
import {
  AttachmentUploadStatusEnum,
  EmbeddedImageAttachment,
  ImageUploadAttachment,
} from 'modules/community/types/post-attachment'

interface ImageAttachmentPreviewProps {
  className?: string
  attachment: ImageUploadAttachment | EmbeddedImageAttachment
  size?: 'small' | 'original'
}

export default function ImageAttachmentPreview({
  attachment,
  className,
  size = 'small',
}: ImageAttachmentPreviewProps) {
  const imageSrc = size === 'small' ? (attachment.previewUrl ?? attachment.url) : attachment.url

  return (
    <div
      className={twMerge(
        'h-full w-full overflow-hidden rounded-lg',
        attachment.status === AttachmentUploadStatusEnum.Uploading && 'bg-slate-100',
        className,
      )}
    >
      {!!imageSrc && (
        <img
          src={imageSrc}
          alt=""
          className="h-full w-full object-cover transition-all md:group-hover:scale-110"
        />
      )}
    </div>
  )
}
