import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import DangerIcon from 'shared/icons/danger-icon'

interface AttachmentFailedPreviewProps {
  className?: string
}

const AttachmentFailedPreview = ({ className }: AttachmentFailedPreviewProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={twMerge(
        'flex h-40 max-w-36 flex-col items-center justify-center gap-1 rounded-lg border border-darkgray-100 p-5',
        className,
      )}
    >
      <DangerIcon />
      <span className="text-center text-sm text-lightgray-100">
        {t('post.attachments.file_upload_failed')}
      </span>
    </div>
  )
}

export default AttachmentFailedPreview
